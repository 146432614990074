import { Component,
         Renderer2,
         AfterViewInit,
         ApplicationRef,
         NgZone,
         OnDestroy,
         inject                         } from '@angular/core';
import { Router,
         NavigationStart                } from '@angular/router';
import { MatIconRegistry                } from '@angular/material/icon';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Platform                       } from '@angular/cdk/platform';
import { combineLatest,
         timer                          } from 'rxjs';
import { filter,
         distinctUntilChanged,
         debounceTime,
         take,
         map,
         pairwise,
         startWith                      } from 'rxjs/operators';
import $                                  from 'jquery';
import axios                              from 'axios';
import { productFruits                  } from 'product-fruits';

import { environment                    } from 'environments/environment';
import { PushNotificationService,
         UserPreferencesService,
         StorageService,
         VersionService,
         KeyboardShortcutsService,
         BroadcastService,
         UserInactivityService,
         NavigationService,
         AuthService,
         EnvironmentService,
         ThreadService                  } from 'app/core';
import { BROADCAST_CONSTANTS            } from 'app/constants';
import { MatDialog                      } from './common';
import { UserInactiveComponent          } from './shared/dialogs/user-inactive/user-inactive.component';

// import all extensions
import 'app/../extensions';
import { DialogsService } from './shared/services/dialogs/dialogs.service';


type _Window = Window & {
  productFruits?: {
    services?: {
      destroy: () => void;
    }
  }
  AfterViewInit?: boolean;
};


type Browser = 'is-chrome' | 'is-firefox' | 'is-safari' | 'is-edge' | 'is-ie' | 'is-other';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private readonly _router          = inject(Router);
  private readonly _dialog          = inject(DialogsService);
  private readonly _auth            = inject(AuthService);
  private readonly _environment     = inject(EnvironmentService);
  private readonly _thread          = inject(ThreadService);
  private readonly _notifications   = inject(PushNotificationService);
  private readonly _userPreferences = inject(UserPreferencesService);
  private readonly _shortcuts       = inject(KeyboardShortcutsService);
  private readonly _storage         = inject(StorageService);
  private readonly _platform        = inject(Platform);
  private readonly _renderer        = inject(Renderer2);
  private readonly _matDialog       = inject(MatDialog);
  private readonly _iconRegistry    = inject(MatIconRegistry);
  private readonly _sanitizer       = inject(DomSanitizer);
  private readonly _version         = inject(VersionService);
  private readonly _broadcast       = inject(BroadcastService);
  private readonly _userInactivity  = inject(UserInactivityService);
  private readonly _navigation      = inject(NavigationService);
  private readonly _appRef          = inject(ApplicationRef);
  private readonly _ngZone          = inject(NgZone);

  constructor () {
    // TEMPORARY: format change for some local storage items -> clear it if invalid
    /* TEMPORARY */ {
    /* TEMPORARY */   const language = localStorage.getItem('language');
    /* TEMPORARY */   const username = localStorage.getItem('username');
    /* TEMPORARY */   let dummy = 0;
    /* TEMPORARY */   try {
    /* TEMPORARY */     if (language && JSON.parse(language)) dummy++;
    /* TEMPORARY */     if (username && JSON.parse(username)) dummy++;
    /* TEMPORARY */   } catch {
    /* TEMPORARY */     // clear local storage, session storage, and reload
    /* TEMPORARY */     localStorage.clear();
    /* TEMPORARY */     sessionStorage.clear();
    /* TEMPORARY */     console.log('about to reload', dummy);
    /* TEMPORARY */     window.location.reload();
    /* TEMPORARY */   }
    /* TEMPORARY */ }


    // listen for keyboard shortcuts without triggering change detection
    this._ngZone.runOutsideAngular(() => {
      window.addEventListener('keydown', (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'd') {
          // if the user presses cmd/ctrl + d, open the debug dialog inside the zone for styles to be properly applied
          this._ngZone.run(() => {
            this._dialog.openDebugDialog();
            event.preventDefault();
          });
        }
      });
    });

    // listen for the tab close event
    window.addEventListener('beforeunload', () => {
      this.ngOnDestroy();
    });

    ////
    //// announces the stable state of the application
    ////
    if (false) {
      this._appRef.isStable.subscribe((isStable) => {
        console.log('is stable', isStable);
      });
    }

    // subscribe to clear all and reload event
    this._broadcast.subscribeToChannel(BROADCAST_CONSTANTS.CLEAR_ALL_AND_RELOAD, false)
    .subscribe(() => void (async () => {
        // clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // deprecate all cookies
        document.cookie.split(";").forEach(function(c) {
          document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        // clear all indexedDB databases
        (await indexedDB.databases()).forEach(db => {
          if ( ! db.name) return;
          // (perhaps listen to the result before proceeding?)
          indexedDB.deleteDatabase(db.name);
        });

        // unregister all service workers
        (await navigator.serviceWorker.getRegistrations()).forEach(x => void x.unregister());

        // finally we reload
        window.location.reload();
    })());


    /*
      load youtube api and initiate product fruits
    */
    if ( ! environment.isolated && ! ('Cypress' in window)) {
      // load youtube api
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);

      let isInitiated = false;
      // initialize product fruits
      combineLatest([
        this._auth.onIsAuthenticated
        .pipe(
          distinctUntilChanged(),
          debounceTime(0),
        ),
        this._userPreferences.watch('language')
        .pipe(
          startWith(null),
          distinctUntilChanged(),
          pairwise(),
          debounceTime(0),
        )
      ])
      .pipe(debounceTime(1000))
      .subscribe(([isAuthenticated, language]) => {
        // have user logged out destroy
        if (isInitiated && (isAuthenticated === false)) {
          productFruits.safeExec(() => {
            isInitiated = false;
            try {
              (window as _Window).productFruits?.services?.destroy();
            } catch (e) {
              console.log(e);
            }
          });
          return;
        }

        // have language changed destroy
        if (isInitiated && (language[0] !== language[1])) {
          productFruits.safeExec(() => {
            isInitiated = false;
            try {
              (window as _Window).productFruits?.services?.destroy();
            } catch (e) {
              console.log(e);
            }
          });
        }

        const username = this._auth.getUsername();

        if (! username) return;

        setTimeout(() => {
          isInitiated = true;
          productFruits.init(
            environment.development ? 'pXtjN0trZX34j2Np' : 'PpivQfJtXHKYQmNA',
            language[1] ?? 'en',
            {
              username,
              signUpAt: this._auth.getCreatedAt(),
            }
          );
        });
      });
    }

    ////
    //// use Material Symbols and additional ones
    ////
    this._iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    void axios.get<string>('assets/icons/tab_close.svg'        ).then(res => this._iconRegistry.addSvgIconLiteral('tab_close',         this._sanitizer.bypassSecurityTrustHtml(res.data)));
    void axios.get<string>('assets/icons/palette_off.svg'      ).then(res => this._iconRegistry.addSvgIconLiteral('palette_off',       this._sanitizer.bypassSecurityTrustHtml(res.data)));
    void axios.get<string>('assets/icons/local_parking_off.svg').then(res => this._iconRegistry.addSvgIconLiteral('local_parking_off', this._sanitizer.bypassSecurityTrustHtml(res.data)));

    ////
    //// announce browser to CSS
    ////
    let browser: Browser;
    if      (this._platform.BLINK)   browser = 'is-chrome';
    else if (this._platform.FIREFOX) browser = 'is-firefox';
    else if (this._platform.SAFARI)  browser = 'is-safari';
    else if (this._platform.EDGE)    browser = 'is-edge';
    else if (this._platform.TRIDENT) browser = 'is-ie';
    else                             browser = 'is-other';
    $('body').addClass(browser);


    ////
    //// initiate notifications service
    ////
    this._auth.onIsAuthenticated
    .subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // logged in
        this._notifications.activate();
      } else {
        // logged out
        this._matDialog.openDialogs.forEach(dialog => dialog.close());
        this._notifications.deactivate();
      }
    });

    ////
    //// listen to global inactivity to open dialog that forces a reload
    ////
    this._userInactivity.globalAuthenticatedInactivity$
    .pipe(filter(Boolean))
    .subscribe(() => this._matDialog.open(UserInactiveComponent, { maxWidth: '600px' }));

  }

  ngAfterViewInit() {
    // expose to cypress
    if ('Cypress' in window) (window as _Window).AfterViewInit = true;
  }

  ngOnDestroy() {
    this._thread.destroy();
    window.removeEventListener('keydown', () => {});
    window.removeEventListener('beforeunload', () => {});
  }
}
