import { Component, Directive, effect, EnvironmentInjector, inject, Injector, input, Type, ViewContainerRef } from '@angular/core';
import { NavigationService } from 'app/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslationModule } from '@app/core/translate/translate.module';
import { RouterModule } from '@angular/router';
import { BurgerItem, FunctionItem, MenuItem, RouteItem } from '@app/core/navigation/types';
import { BehaviorSubject, mergeWith, Observable, shareReplay, take } from 'rxjs';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';
import { ReplaceParametersPipe } from '../../pipes/replace-parameters.pipe';
import { FROM_BURGER_MENU, PARENT_MENU_ITEM } from '@app/core/navigation/constants';
import { PathMatchPipe } from '../../pipes/path-match.pipe';
import { SubmenuComponent } from '../submenu/submenu.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsObservablePipe } from '../../pipes/as-observable.pipe';

type BurgerSubitem = BurgerItem['items'][number];
type ExtendedBurgerSubitem = BurgerSubitem & { submenuOpen$: Observable<boolean>; toggleSubmenu: () => void };

@Directive({
  selector:   '[appBurgerSubmenu]',
  standalone: true,
  host:       {
    '[class.open]': 'open()',
  }
})
export class BurgerSubmenuDirective {
  public readonly open = input.required          ({ transform: coerceBooleanProperty });
  public readonly item = input.required<MenuItem>({ alias: 'appBurgerSubmenu'        });

  private readonly _environmentInjector = inject(EnvironmentInjector);
  private readonly _containerRef        = inject(ViewContainerRef);

  constructor () {
    effect(() => {
      // the item must have a submenu to open
      const item = this.item();
      if ( ! ('submenu' in item) || ! item.submenu) return;

      if (this.open()) {
        // attach the submenu component to the view container when the submenu is opened
        if (Array.isArray(item.submenu)) {
          // is a submenu
          const submenu = this.addSubmenuComponent(SubmenuComponent, item);
          if (submenu) submenu.instance.items = item.submenu;
        } else {
          // is a component
          this.addSubmenuComponent(item.submenu, item);
        }
      } else {
        // clear the view container when the submenu is closed
        this._containerRef.clear();
      }
    });
  }

  private addSubmenuComponent<T> (component: Type<T>, item: MenuItem) {
    this._containerRef.clear();

    const injector = Injector.create({
      providers: [
        { provide: PARENT_MENU_ITEM, useValue: item },
        { provide: FROM_BURGER_MENU, useValue: true }
      ],
      parent: this._environmentInjector
    });

    return this._containerRef.createComponent<T>(component, {
      injector: injector,
    });
  }
}

@Component({
  selector: 'app-burger-menu',
  imports:  [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatListModule,
    TranslationModule,
    ReplaceParametersPipe,
    SharedPipesModule,
    PathMatchPipe,
    BurgerSubmenuDirective,
    AsObservablePipe
  ],
  templateUrl: './burger-menu.component.html',
  styleUrls:   ['./burger-menu.component.scss'],
  providers:   [
    PathMatchPipe,
  ]
})
export class BurgerMenuComponent {
  private readonly _parentMenuItem = inject(PARENT_MENU_ITEM);
  private readonly _pathMatchPipe  = inject(PathMatchPipe);

  protected readonly items = ('burger' in this._parentMenuItem)
    ? this._parentMenuItem.items.map((x: BurgerSubitem) => {
      const toggle$ = new BehaviorSubject<boolean>(false);
      const open$ = toggle$.pipe(
        mergeWith(
          this._pathMatchPipe.transform(x)
            .pipe(take(1))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
      );

      // this function will subscribe to an already subscribed to observable and thus get the correct value!
      const toggleFn = () => open$.pipe(take(1)).subscribe(x => toggle$.next( ! x));

      return { ...x, submenuOpen$: open$, toggleSubmenu: toggleFn } satisfies ExtendedBurgerSubitem;
    })
    : [];

  protected isRouteItem (item: BurgerSubitem): item is RouteItem & ExtendedBurgerSubitem {
    return NavigationService.isRouteItem(item);
  }
  protected isFunctionItem (item: BurgerSubitem): item is FunctionItem & ExtendedBurgerSubitem {
    return NavigationService.isFunctionItem(item);
  }
}