import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, inject, Injector, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from '@app/common/common.module';
import { EnvironmentService, NavigationService } from '@app/core';
import { BurgerItem, MenuItem, NavigationData } from '@app/core/navigation/types';
import { TranslationModule } from '@app/core/translate/translate.module';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';
import { BehaviorSubject, combineLatest, debounceTime, delay, distinctUntilChanged, fromEvent, map, of, shareReplay, startWith, switchMap, take } from 'rxjs';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { ReplaceParametersPipe } from './pipes/replace-parameters.pipe';
import { PARENT_MENU_ITEM } from '@app/core/navigation/constants';
import { PathMatchPipe } from './pipes/path-match.pipe';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { inOutAnimation } from '@app/shared/animations';
import { AsObservablePipe } from './pipes/as-observable.pipe';


const openDelay  = 350;
const closeDelay = 200;

function isNavigationData (x: unknown): x is NavigationData {
  return typeof x === 'object' && x !== null && 'nav' in x;
}

function splitItems (items: MenuItem[]): { top: MenuItem[]; bottom: MenuItem[] } {
  return items.reduce((acc, item) => {
    // the logo should go in the top
    if ('logo' in item && item.logo) {
      acc.top.push(item);
      return acc;
    }

    if ('position' in item && item.position === 'bottom') {
      acc.bottom.push(item);
    } else {
      acc.top.push(item);
    }
    return acc;

  }, { top: [], bottom: [] } as { top: MenuItem[]; bottom: MenuItem[] });
}

function filterItems (items: MenuItem[], data: unknown ): MenuItem[] {
  if (! isNavigationData(data)) return items;

  return items.reduce<MenuItem[]>((acc, item) => {
    // If item lack selector, don't apply filter
    if (! ('selector' in item && item.selector)) return acc.concat(item);

    // If item is not in the resources list, don't apply filter
    if (data.nav.resources.includes(item.selector) != (data.nav.action == 'hide')) return acc.concat(item);

    return acc;
  }, []);
}

@Component({
  selector: 'app-navigation-rail',
  imports:  [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    TranslationModule,
    AppCommonModule,
    SharedPipesModule,
    ReplaceParametersPipe,
    PathMatchPipe,
    AsObservablePipe
  ],
  templateUrl: './navigation-rail.component.html',
  styleUrl:    './navigation-rail.component.scss',
  animations:  [
    inOutAnimation
  ]
})
export class NavigationRailComponent {
  protected readonly environment          = inject(EnvironmentService);
  private   readonly _navigation          = inject(NavigationService);
  private   readonly _environmentInjector = inject(EnvironmentInjector);

  protected readonly topMode$ = fromEvent(window, 'resize')
    .pipe(
      startWith(null),
      map(() => {
        // find the breakpoint which is stored as the css-variable "--navigation-breakpoint" in :root
        // (defined in "navigation-rail.component.scss")
        const breakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--navigation-breakpoint'));
        if (isNaN(breakpoint)) return false;
        return document.documentElement.clientWidth <= breakpoint;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  protected readonly burgerMenuItem$ = this._navigation.items$
    .pipe(
      map(items => items
        .filter((x): x is BurgerItem['items'][number] => ! ('logo' in x || 'burger' in x))
        .filter(x => ! x.visibleInTopNav)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  @ViewChild('submenuContainer', { read: ViewContainerRef, static: true })
  private readonly submenuContainer?: ViewContainerRef;

  protected readonly topMenuItems$;
  protected readonly bottomMenuItems$;


  private readonly _itemHover$       = new BehaviorSubject<MenuItem | null>(null);
  private readonly _submenuHover$    = new BehaviorSubject<MenuItem | null>(null);

  protected readonly activeRouteItem$;

  private activeSubmenuItem: MenuItem | null = null;
  protected readonly submenuOpened$ = new BehaviorSubject(false);

  constructor () {

    this.activeRouteItem$ = combineLatest({
      items: this._navigation.items$,
      path:  this._navigation.path$.pipe(map(x => x.join('/')))
    })
      .pipe(
        map(x => x.items
          .filter(x => 'path' in x)
          .find(item => {
            // if the item has the exactMatch flag, we should compare the paths exactly
            if (item.exactMatch) return item.path == x.path;

            // otherwise, we should check if the path starts with the item's path
            let path = item.path;
            // remove first slash if the path is more than just a slash
            if (path.length > 1 && path.startsWith('/')) path = path.slice(1);

            return x.path.startsWith(path);
          })
        ),
        distinctUntilChanged(),
        shareReplay(1)
      );

    const delayedItemHover$    = this._itemHover$   .pipe(switchMap(x => of(x).pipe(delay(x ? openDelay : closeDelay))));
    const delayedSubmenuHover$ = this._submenuHover$.pipe(switchMap(x => of(x).pipe(delay(x ? 0         : closeDelay))));

    combineLatest({
      itemHover:       delayedItemHover$,
      submenuHover:    delayedSubmenuHover$,
      activeRouteItem: this.activeRouteItem$
    })
      .pipe(
      // needed as sometimes the created submenu does not show up even though it is created
      // (for example /schedule/:did/account)
        debounceTime(0),
        map(x => x.itemHover ?? x.submenuHover ?? (x.activeRouteItem?.openWhenActive ? x.activeRouteItem : null)),
        distinctUntilChanged()
      )
      .subscribe(x => {
      // set open state
        if (x && 'submenu' in x && x.submenu) {
          this.submenuOpened$.next(!! x);
          this.activeSubmenuItem = x;

          if (Array.isArray(x.submenu)) {
          // is a submenu
            const submenu = this.addSubmenuComponent(SubmenuComponent, x);
            if (submenu) submenu.instance.items = x.submenu;
          }
          else if (typeof x.submenu === 'function') {
          // is a component
            this.addSubmenuComponent(x.submenu, x);
          }

        } else {
          this.clearAndCloseSubmenu();
        }
      });

    // close the submenu when the navigation changes
    this._navigation.items$.subscribe(() => this.clearAndCloseSubmenu());


    this.topMenuItems$    = this._navigation.items$.pipe(map(items => splitItems(items).top   ), switchMap(items => this._navigation.data$.pipe(map(data => filterItems(items, data)))));
    this.bottomMenuItems$ = this._navigation.items$.pipe(map(items => splitItems(items).bottom));

  }


  private clearAndCloseSubmenu () {
    this.submenuOpened$.next(false);
    this.activeSubmenuItem = null;
    this.submenuContainer?.clear();
  }

  private addSubmenuComponent<T> (component: Type<T>, item: MenuItem) {
    if ( ! this.submenuContainer) return;

    this.submenuContainer.clear();

    const injector = Injector.create({
      providers: [
        { provide: PARENT_MENU_ITEM, useValue: item }
      ],
      parent: this._environmentInjector
    });

    return this.submenuContainer.createComponent<T>(component, {
      injector: injector,
    });
  }


  protected isRouteItem    = NavigationService.isRouteItem;
  protected isFunctionItem = NavigationService.isFunctionItem;
  protected isLogoItem     = NavigationService.isLogoItem;


  protected onMouseOverItem (item: MenuItem) {
    this.topMode$
      .pipe(take(1))
      .subscribe(topMode => {
      // trigger only if the item has a submenu
        if ( ! topMode && 'submenu' in item && item.submenu) this._itemHover$.next(item);
      });
  }
  protected onMouseLeaveItem (item: MenuItem) {
    this.topMode$
      .pipe(take(1))
      .subscribe(topMode => {
      // trigger only if the item has a submenu
        if ( ! topMode && 'submenu' in item && item.submenu) this._itemHover$.next(null);
      });
  }

  protected onBurgerClick () {
    this.burgerMenuItem$
      .pipe(take(1))
      .subscribe(items => this._itemHover$
        .next({
          burger:  true,
          items:   items,
          submenu: BurgerMenuComponent
        } as BurgerItem)
      );
  }
  protected onItemClick (item: MenuItem) {
    this.topMode$
      .pipe(take(1))
      .subscribe(topMode => {
        if (topMode) {
        // trigger only if the item has a submenu
          if ('submenu' in item && item.submenu) this._itemHover$.next(item);
        } else {
        // if route item in side mode, close the menu to not block the view
          if (this.isRouteItem(item)) this._itemHover$.next(null);
        }
      });
  }
  protected onBackdropClick () {
    this._itemHover$.next(null);
  }

  protected onMouseOverSubmenu () {
    this._submenuHover$.next(this.activeSubmenuItem);
  }
  protected onMouseLeaveSubmenu () {
    this._submenuHover$.next(null);
  }

}
