<div #chat
  class="wrapper"
  [class.visible]="visible()"
  [class.drag-handle-active]="dragHandleActive()"
  [style.--width.px]="width()"
  [style.--height.px]="height()"
  cdkDrag
  [cdkDragFreeDragPosition]="position()"
  (cdkDragEnded)="dragEnded()"
>
  <div class="toolbar"
    cdkDragHandle
    (mousedown)="dragHandleActive.set(true)"
  >
    <button mat-icon-button color="white" class="move">
      <mat-icon>drag_pan</mat-icon>
    </button>
    <span class="spacer"></span>
    <button mat-icon-button color="white"
      (mousedown)="hide()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div id="hubspot-chat-container">
    <div class="loading">
      {{ 'shared.components.hubspot-chat.loading' | translate }}
    </div>
    <!-- the hubspot chat will be inserted here -->
  </div>
</div>