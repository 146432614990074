<div class="container">

  <mat-nav-list>
    @for (_item of items; track $index) {
      @if (!(_item.hidden | asObservable | async)) {

        @let disabled = (_item.disabled | asObservable | async);
        @let enabled  = !disabled;

        @if (_item.submenu) {
          @let item = _item;
          <!-- the appBurgerSubmenu directive is responsible for inserting the submenu component to the view -->
          <mat-list-item
            [class.active]="item | pathMatch | async"
            [disabled]="disabled"
            (click)="enabled && item.toggleSubmenu()"
            [appBurgerSubmenu]="item" [open]="item.submenuOpen$ | async"
          >
            <mat-icon matListItemIcon>{{ item.icon | asObservable | async }}</mat-icon>
            <span class="label" [innerHTML]="item.name | translate"></span>
            @if (enabled) {
              <div matListItemMeta class="flex-align-center">
                @if (item.submenuOpen$ | async) {
                  <mat-icon>keyboard_arrow_up</mat-icon>
                } @else {
                  <mat-icon>keyboard_arrow_down</mat-icon>
                }
              </div>
            }
          </mat-list-item>
          <!-- its attached to this location -->
        } @else {
          @if (_item | typeGuard:isRouteItem; as item) {
            <mat-list-item
              [routerLink]="enabled ? (item.path | replaceParameters | async) : null"
              [queryParamsHandling]="item.queryParamsHandling"
              [class.active]="item | pathMatch | async"
              [disabled]="disabled"
            >
              <mat-icon matListItemIcon>{{ item.icon | asObservable | async }}</mat-icon>
              <span class="label" [innerHTML]="item.name | translate"></span>
            </mat-list-item>
          }
          @if (_item | typeGuard:isFunctionItem; as item) {
            <mat-list-item
              [disabled]="disabled"
            >
              <mat-icon matListItemIcon>{{ item.icon | asObservable | async }}</mat-icon>
              <span class="label" [innerHTML]="item.name | translate"></span>
            </mat-list-item>
          }
        }
      }
    }
  </mat-nav-list>

</div>