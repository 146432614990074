<app-navigation-rail></app-navigation-rail>

<div class="content">
  <router-outlet></router-outlet>
  <app-hubspot-chat></app-hubspot-chat>
</div>

<!-- REMOVE AND REMOVE CORRESPONDING CSS, WAS ONLY MEANT AS A TEMPORARY DEV TOOL -->
<!-- <div class="activity-wrapper">
  <div class="global" [class.active]="_userInactivity.globallyActive$ | async"></div>
  <div class="tab" [class.active]="_userInactivity.tabActive$ | async"></div>
</div> -->