import { BrowserModule                   } from '@angular/platform-browser';
import { NgModule                        } from '@angular/core';
import { provideHttpClient,
         withInterceptorsFromDi          } from '@angular/common/http';
import { BrowserAnimationsModule         } from '@angular/platform-browser/animations';
import { MatTooltipDefaultOptions,
         MAT_TOOLTIP_DEFAULT_OPTIONS     } from '@angular/material/tooltip';
import { ServiceWorkerModule             } from '@angular/service-worker';
import { TranslateLoader,
         TranslateModule,
         MissingTranslationHandler,
         TranslateCompiler               } from '@ngx-translate/core';
import { DragulaModule                   } from 'ng2-dragula';

import { environment                     } from '../environments/environment';
import { RoutingModule                   } from 'app/core/routing/routing.module';
import { CoreModule                      } from 'app/core/core.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { AppComponent                    } from 'app/app.component';
import { CustomLoader,
         CustomTranslateCompiler,
         CustomMissingTranslationHandler } from 'app/core/translate/translation';
import { EnvironmentService              } from 'app/core';
import { DisplayNamePipe,
         EventDisplayNamePipe,
         EventColorPipe,
         CourseColorPipe                 } from 'app/shared/pipes/common/common.pipe';
import { EnvironmentCollectionPipe       } from 'app/core/environment/environment.pipe';
import { NavigationRailComponent         } from './shared/components/navigation-rail/navigation-rail.component';
import { matTooltipDefaultOptions        } from './constants/common';
import { ROUTER_CONFIGURATION } from '@angular/router';
import { SharedPipesModule } from './shared/pipes/pipes.module';
import { SharedDirectivesModule } from './shared/directives/directives.module';
import { SharedComponentsModule } from './shared/components/components.module';
import { SharedFormsModule } from './shared/forms/forms.module';
import { SharedDialogsModule } from './shared/dialogs/dialogs.module';
import { SharedTablesModule } from './shared/tables/tables.module';
import { HubspotChatComponent } from "./shared/components/hubspot-chat/hubspot-chat.component";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide:  TranslateLoader,
        useClass: CustomLoader
      },
      missingTranslationHandler: {
        provide:  MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      compiler: {
        provide:  TranslateCompiler,
        useClass: CustomTranslateCompiler,
        deps: [
          EnvironmentService
        ]
      }
    }),
    CoreModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    SharedFormsModule,
    SharedDialogsModule,
    SharedTablesModule,
    RoutingModule,
    AppCommonModule,
    NavigationRailComponent,
    HubspotChatComponent,
    DragulaModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enable as long as we are not running locally
      enabled: environment.built,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    // provide the following pipes so they can be injected into components/services
    // (not into the templates, thats what "imports: [...]" are for)
    DisplayNamePipe,
    EventDisplayNamePipe,
    EventColorPipe,
    CourseColorPipe,
    EnvironmentCollectionPipe,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: matTooltipDefaultOptions
    },
    {
      provide: ROUTER_CONFIGURATION,
      useValue: { defaultQueryParamsHandling: 'merge' }
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
