import { Component, inject } from '@angular/core';
import { NavigationService } from 'app/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslationModule } from '@app/core/translate/translate.module';
import { RouterModule } from '@angular/router';
import { SubmenuItem } from '@app/core/navigation/types';
import { BehaviorSubject, map } from 'rxjs';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';
import { ReplaceParametersPipe } from '../../pipes/replace-parameters.pipe';
import { FROM_BURGER_MENU, PARENT_MENU_ITEM } from '@app/core/navigation/constants';
import { AsObservablePipe } from '../../pipes/as-observable.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedDirectivesModule } from '@app/shared/directives/directives.module';
import { MatButtonModule } from '@angular/material/button';

type _SubmenuItem = SubmenuItem & { unlocking: boolean };

@Component({
  selector: 'app-submenu',
  imports:  [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    TranslationModule,
    ReplaceParametersPipe,
    SharedPipesModule,
    AsObservablePipe,
    SharedDirectivesModule
  ],
  templateUrl: './submenu.component.html',
  styleUrls:   ['./submenu.component.scss'],
  host:        {
    '[class.from-burger-menu]': 'fromBurgerMenu'
  }
})
export class SubmenuComponent {
  private   readonly parentMenuItem = inject(PARENT_MENU_ITEM);
  protected readonly fromBurgerMenu = inject(FROM_BURGER_MENU, { optional: true }) ?? false;

  protected readonly header = 'name' in this.parentMenuItem ? this.parentMenuItem.name : '';

  protected readonly premiumLabelContext: { item: _SubmenuItem, disabled: boolean };

  set items (items: SubmenuItem[]) { this._items$.next(items) }
  private readonly _items$ = new BehaviorSubject<SubmenuItem[]>([]);
  protected readonly items$ = this._items$.pipe(
    // when hovering the unlock button, the icon should change
    map(items => items.map(item => ({ ...item, unlocking: false }) as _SubmenuItem))
  );

  protected isRouteItem    = NavigationService.isRouteItem;
  protected isFunctionItem = NavigationService.isFunctionItem;
}