<div class="rail">

  <!-- the burger menu in case top navigation -->
  @if ((topMode$ | async) && (burgerMenuItem$ | async | length)) {
    <div class="item burger" (click)="onBurgerClick()">
      <div class="icon-badge">
        <mat-icon>menu</mat-icon>
      </div>
    </div>
  }

  @for (menu$ of [topMenuItems$, bottomMenuItems$]; track $index) {

    @for (_item of (menu$ | async); track $index) {

      @if (_item | typeGuard:isLogoItem; as item) {
        <div class="logo">
          @if (environment.whiteLabel) {
            <img class="symbol" [src]="environment.symbol_color">
          } @else {
            <img class="symbol for-top-nav" src="assets/rs-name-black.svg">
            <img class="symbol for-side-nav" [src]="environment.symbol_color">
          }
          @if (environment.whiteLabel) {
            <img class="for-top-nav" src="assets/powered-by-rs-for-top-nav.svg">
            <img class="for-side-nav" src="assets/powered-by-rs-for-side-nav.svg">
          }
        </div>
      }

      @if (_item | typeGuard:isRouteItem; as item) {
        @if (!(item.hidden | asObservable | async)) {
          @let disabled = item.disabled | asObservable | async;
          @let enabled  = !disabled;
          <div class="item route"
            [routerLink]="enabled ? (item.path | replaceParameters | async) : null"
            [queryParamsHandling]="item.queryParamsHandling"
            [class.active]="item | pathMatch | async"
            [class.disabled]="disabled"
            [class.openWhenActive]="item.openWhenActive"
            [class.progress]="item.progress | async"
            [class.visible-in-top-nav]="item.visibleInTopNav"
            [style.--progress]="item.progress | async"
            [attr.cypress]="item.cypress"
            (mouseover)="enabled && onMouseOverItem(item)" (mouseleave)="enabled && onMouseLeaveItem(item)"
            (click)="enabled && onItemClick(item)"
          >
            <div class="icon-badge">
              @let badgeStr = item.badge | async | stringify;
              <mat-icon
                [matBadge]="badgeStr"
                [matBadgeHidden]=" ! badgeStr"
                [ngClass]="item.badgeClass | async"
                aria-hidden="false"
              >
                {{ item.icon | asObservable | async }}
              </mat-icon>
            </div>
            <span class="label" [innerHTML]="item.name | translate"></span>
          </div>
        }
      }

      @if (_item | typeGuard:isFunctionItem; as item) {
        @if (!(item.hidden | asObservable | async)) {
          @let disabled = item.disabled | asObservable | async;
          @let enabled  = !disabled;
          <div class="item function"
            [class.disabled]="disabled"
            [class.visible-in-top-nav]="item.visibleInTopNav"
            [attr.cypress]="item.cypress"
            (mouseover)="enabled && onMouseOverItem(item)" (mouseleave)="enabled && onMouseLeaveItem(item)"
            (click)="enabled && item.function?.($event); enabled && onItemClick(item);"
          >
            <div class="icon-badge">
              @let badgeStr = item.badge | async | stringify;
              <mat-icon
                [matBadge]="badgeStr"
                [matBadgeHidden]=" ! badgeStr"
                [ngClass]="item.badgeClass | async"
                aria-hidden="false"
              >
                {{ item.icon | asObservable | async }}
              </mat-icon>
            </div>
            <span class="label" [innerHTML]="item.name | translate"></span>
          </div>
        }
      }
    }

    @if ($first) {
      <span class="spacer"></span>
    }

  }
</div>

@if ((topMode$ | async) && (submenuOpened$ | async)) {
  <div @inOutAnimation class="backdrop" (click)="onBackdropClick()"></div>
}

<div
  class="submenu dark-scrollbar"
  [class.overlay]=" ! (activeRouteItem$ | async)?.openWhenActive"
  [class.opened]="submenuOpened$ | async"
  (mouseover)="onMouseOverSubmenu()" (mouseleave)="onMouseLeaveSubmenu()"
>
  <ng-container #submenuContainer></ng-container>
</div>