<div class="container">

  @if (header) {
    <span class="header" [innerHTML]="header | translate"></span>
  }

  <mat-nav-list>
    @for (_item of items$ | async; track $index) {
      @if (!(_item.hidden | asObservable | async)) {
        @let disabled = _item.disabled | asObservable | async;
        @let enabled  = !disabled;

        @if (_item | typeGuard:isRouteItem; as item) {
          <mat-list-item
            [disabled]="disabled"
            [routerLink]="enabled ? (item.path | replaceParameters | async) : null"
            [queryParamsHandling]="item.queryParamsHandling"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: !! item.exactMatch }"
            [attr.cypress]="item.cypress"
          >
            <mat-icon matListItemIcon>{{ item.icon | asObservable | async }}</mat-icon>
            <span [innerHTML]="item.name | translate"></span>
            <div matListItemMeta>
              <ng-container *ngTemplateOutlet="premiumLabelTmpl; context: { item, disabled }"></ng-container>
            </div>
          </mat-list-item>
        }
        @if (_item | typeGuard:isFunctionItem; as item) {
          <mat-list-item
            [disabled]="disabled"
            [attr.cypress]="item.cypress"
            (click)="enabled && item.function?.($event)"
          >
            <mat-icon matListItemIcon>{{ item.icon | asObservable | async }}</mat-icon>
            <span [innerHTML]="item.name | translate"></span>
            <div matListItemMeta>
              <ng-container *ngTemplateOutlet="premiumLabelTmpl; context: { item, disabled }"></ng-container>
            </div>
          </mat-list-item>
        }
      }
    }
  </mat-nav-list>

</div>

<ng-template #premiumLabelTmpl [templateTypes]="premiumLabelContext" let-item="item" let-disabled="disabled">
  @if (disabled && item.unlockable) {
    <button mat-icon-button color="accent"
      class="unlock"
      [matTooltip]="'common.unlock' | translate"
      matTooltipPosition="right"
      (click)="item.unlockable.click?.()"
      (mouseenter)="item.unlocking = true"
      (mouseleave)="item.unlocking = false"
    >
      <mat-icon>{{ item.unlocking ? 'lock_open' : 'lock' }}</mat-icon>
    </button>
  }
</ng-template>