import { inject, Pipe, PipeTransform } from "@angular/core";
import { NavigationService } from "@app/core";
import { map, Observable, of } from "rxjs";
import { isEqual } from "lodash";
import { MenuItem } from "@app/core/navigation/types";


@Pipe({
  standalone: true,
  name: 'pathMatch'
})
export class PathMatchPipe implements PipeTransform {
  private readonly _navigation = inject(NavigationService);

  transform (item: MenuItem): Observable<boolean> {
    if ( ! ('path' in item)) return of(false);

    // if it has child routes, use those
    const childRouteItems = Array.isArray(item.submenu) ? item.submenu.filter(x => 'path' in x) : [];
    const routeItems = [item, ...childRouteItems];

    // split path by '/' and make ignore leading and trailing slashes by filtering empty strings
    const mapped = routeItems.map(x => ({
      path:       (x.activePath ?? x.path).split('/').filter(Boolean),
      exactMatch: x.exactMatch
    }));

    return this._navigation.path$.pipe(
      map(activePath => mapped.some(x => {
        // if the item has the exactMatch flag, we require an exact match
        // otherwise check if a path starts with the active path
        return x.exactMatch
          ? isEqual(x.path, activePath)
          : activePath.length >= x.path.length && isEqual(x.path, activePath.slice(0, x.path.length));
      }))
    );
  }
}
