import { Component, computed, effect, ElementRef, inject, signal, viewChild } from '@angular/core';
import { HubspotChatService } from '@app/shared/services/hubspot-chat/hubspot-chat.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslationModule } from '@app/core/translate/translate.module';
import { CdkDrag, CdkDragHandle, Point } from '@angular/cdk/drag-drop';
import { debounceTime, fromEvent, map, startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import  '@app/shared/services/hubspot-chat/interface';

const preferredDimensions = { width: 376, height: 530 };

function getBoundingBox (elem: HTMLElement) {
  const { left, right, top, bottom, width, height } = elem.getBoundingClientRect();
  return { left, right, top, bottom, width, height };
}

@Component({
    selector: 'app-hubspot-chat',
    imports: [
        MatButtonModule,
        MatIconModule,
        TranslationModule,
        CdkDrag,
        CdkDragHandle
    ],
    templateUrl: './hubspot-chat.component.html',
    styleUrl: './hubspot-chat.component.scss'
})
export class HubspotChatComponent {
  private readonly _chat = inject(HubspotChatService);

  private readonly _host          = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly _parentElement = this._host.nativeElement.parentElement;
  private readonly _chatElement   = viewChild<never, ElementRef<HTMLElement>>('chat', { read: ElementRef });

  protected readonly visible = computed(() => this._chat.chatVisible());

  protected readonly dragHandleActive = signal(false);

  protected readonly position = signal<Point>({ x: 0, y: window.innerHeight });   // initial position is the bottom-left corner
  protected readonly width    = signal(preferredDimensions.width );
  protected readonly height   = signal(preferredDimensions.height);

  constructor () {
    // load the hubspot API
    this._chat.loadAPI({ inlineEmbedSelector: '#hubspot-chat-container' })
    .pipe(takeUntilDestroyed())
    .subscribe(api => {
      if ( ! api ) return;
      // setup/refresh the chat widget
      const status = api.widget.status();
      if (status.loaded) api.widget.refresh();
      else               api.widget.load();
    });

    // deactivate drag handle on mouse up (need not be on the drag handle)
    effect(() => {
      if ( ! this.dragHandleActive()) return;
      const onMouseUp = () => {
        this.dragHandleActive.set(false);
        document.removeEventListener('mouseup', onMouseUp);
      }
      document.addEventListener('mouseup', onMouseUp);
    });

    // initial position and repositioning when the window is resized
    fromEvent(window, 'resize')
    .pipe(
      map(() => undefined),
      startWith(this.position()),
      debounceTime(100),
      takeUntilDestroyed()
    )
    .subscribe(x => this._reposition(x));
  }

  private _reposition (positionOverride?: Point) {
    const containerElem = this._parentElement;
    const chatElem      = this._chatElement()?.nativeElement;
    if ( ! containerElem || ! chatElem ) return;

    // find the bounding boxes of the container and the chat
    const boundingBox = getBoundingBox(containerElem);
    const chatBox     = getBoundingBox(chatElem);

    // try override
    if (positionOverride) {
      const { x, y } = positionOverride;
      chatBox.left   = x;
      chatBox.right  = x + chatBox.width;
      chatBox.top    = y;
      chatBox.bottom = y + chatBox.height;
    }

    // margin
    const margin = {
      left:   25,
      right:  25,
      top:    36, // 36 is the height of the header
      bottom: 25
    };

    // height and width of the chat
    const maxWidth  = boundingBox.width  - margin.left - margin.right;
    const maxHeight = boundingBox.height - margin.top  - margin.bottom;
    chatBox.width  = Math.min(preferredDimensions.width,  maxWidth );
    chatBox.height = Math.min(preferredDimensions.height, maxHeight);

    // check if the chat is outside the bounding box
    const dx = (chatBox.left  < boundingBox.left  + margin.left ) ? margin.left
             : (chatBox.right > boundingBox.right - margin.right) ? boundingBox.width - margin.right - chatBox.width
             :                                                      chatBox.left - boundingBox.left;
    const dy =  (chatBox.top    < boundingBox.top    + margin.top   ) ? margin.top
              : (chatBox.bottom > boundingBox.bottom - margin.bottom) ? boundingBox.height - margin.bottom - chatBox.height
              :                                                         chatBox.top - boundingBox.top;

    const x = boundingBox.left + dx;
    const y = boundingBox.top  + dy;

    // update the position and dimensions
    this.position.set({ x, y });
    this.width   .set(chatBox.width );
    this.height  .set(chatBox.height);
  }

  protected hide () {
    this._chat.hideChat();
  }

  protected dragEnded () {
    this._reposition();
  }

}
